import React from 'react';
import LinkifyIt, { Match } from 'linkify-it';
import { Link, LinkProps } from '@mui/material';

const hostnamesToTruncate = /sharepoint.com/;

export function AutoLink({ children, ...linkProps }: LinkProps) {
  const linkify = new LinkifyIt();

  function parseString(string: string) {
    if (!string) {
      return '';
    }

    const matches: Match[] | null = linkify.match(string);

    if (!matches) {
      return string;
    }

    const elements: React.ReactNode[] = [];
    let lastIndex = 0;

    matches.forEach((match, i) => {
      if (match.index > lastIndex) {
        elements.push(string.substring(lastIndex, match.index));
      }

      let { text } = match;

      if (hostnamesToTruncate.test(match.url)) {
        text = new URL(match.url).hostname;
      } else if (text.length > 40) {
        text = `${new URL(match.url).hostname}/…${text.slice(-16)}`;
      }

      const component = (
        <Link href={match.url} key={i} {...linkProps}>
          {text}
        </Link>
      );

      elements.push(component);

      lastIndex = match.lastIndex;
    });

    if (string.length > lastIndex) {
      elements.push(string.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  }

  function parse(childNodes: React.ReactNode, key = 0): React.ReactNode {
    if (typeof childNodes === 'string') {
      return parseString(childNodes);
    }

    if (React.isValidElement(childNodes) && childNodes.type !== 'a' && childNodes.type !== 'button') {
      const { props, type } = childNodes as React.ReactElement;
      //@ts-ignore
      if (typeof type === 'function' && type.displayName === 'WithStyles(Link)') {
        return childNodes;
      }
      //@ts-ignore
      return React.cloneElement(childNodes, { key }, parse(props.children));
    }

    if (Array.isArray(childNodes)) {
      return childNodes.map((child, i) => parse(child, i));
    }

    return childNodes;
  }

  return parse(children);
}
