import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { useMyAccountQuery } from 'src/api';
import { useAuth } from 'src/contexts/AuthProvider';
import { useIsMountedRef } from 'src/hooks/useIsMountedRef';
import { GITHUB_REPO, PATH_APP } from 'src/paths';

interface AccountMenuProps {
  handleClose?: () => void;
}

export function AccountMenu({ handleClose }: AccountMenuProps) {
  const { push } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { user, isAdmin, ability, logout } = useAuth();
  const datevExports = useMyAccountQuery().data?.myAccount.datevExports;
  const isMountedRef = useIsMountedRef();

  function handleClickItem(path: string) {
    handleClose?.();
    push(path);
  }

  async function handleLogout() {
    try {
      await logout();

      if (isMountedRef.current) {
        handleClose?.();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  }

  const menuItems = useMemo(() => {
    const items = [];
    if (ability?.can('update', 'account')) {
      items.push({ label: 'Club Einstellungen', linkTo: PATH_APP.settings.root });
    }

    if (ability?.can('update', 'invoice') && datevExports?.enabled) {
      items.push({ label: 'Buchungsstapel', linkTo: PATH_APP.invoices.root });
    }

    if (isAdmin) {
      items.push({ label: 'Benutzer', linkTo: PATH_APP.users.root });
      items.push({ label: 'Accounts', linkTo: PATH_APP.accounts.root });
    }

    return items;
  }, [ability, isAdmin, datevExports?.enabled]);

  return (
    <>
      <Box my={1.5} px={2.5}>
        <Typography variant="subtitle2" noWrap>
          {user?.displayName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {user?.email}
        </Typography>
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack sx={{ p: 1 }}>
        {menuItems.map((option) => (
          <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
            {option.label}
          </MenuItem>
        ))}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
        Logout
      </MenuItem>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <MenuItem
        onClick={() => handleClickItem(isAdmin ? `${GITHUB_REPO}/commit/${process.env.BUILD_ID}` : '')}
      >
        Version {process.env.BUILD_ID?.slice(0, 7)} vom
        <br />
        {new Date(+(process.env.BUILD_TIME ?? 0)).toLocaleString()}
      </MenuItem>
    </>
  );
}
