import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from '@hello-pangea/dnd';
import { Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  DashboardKeyInventoryDocument,
  Inventory,
  useDashboardKeyInventoryQuery,
  useUpdateInventoryMutation,
} from 'src/api';
import { NotePopover } from 'src/components/app/NotePopover';
import { Iconify } from 'src/components/Iconify';
import { Link } from 'src/components/Link';
import Scrollbar from 'src/components/Scrollbar';
import { TableNoData } from 'src/components/table';
import { useLocales } from 'src/hooks/useLocales';
import { useOrderedData } from 'src/hooks/useOrderedData';
import { PATH_APP } from 'src/paths';
import { fCurrency, fNumber } from 'src/utils/formatNumber';
import { DashboardParams } from './Dashboard';

export function DashboardKeyRight({ seasonId, leagueIds }: DashboardParams) {
  const { translate } = useLocales();

  const { data } = useDashboardKeyInventoryQuery({ variables: { seasonId, leagueIds } });

  const [updateInventoryMutation] = useUpdateInventoryMutation({
    refetchQueries: [DashboardKeyInventoryDocument],
  });

  const [orderedItems, updateOrder, isLoading] = useOrderedData<Inventory>(
    data?.allInventory.inventory as Inventory[],
    'id',
    'id',
    updateInventoryMutation,
    'keyRightOrder',
  );

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardHeader title="Key Rights" />
      <Scrollbar>
        <Table sx={{ position: 'relative', minWidth: 720 }}>
          <TableHead sx={{ position: 'sticky', top: 0, bgcolor: 'white' }}>
            <TableRow>
              <TableCell sx={{ p: 0 }} />
              <TableCell>Name</TableCell>
              <TableCell>Einheit</TableCell>
              <TableCell>Bestand</TableCell>
              <TableCell>Preis / Einheit</TableCell>
              <TableCell>Verkauft</TableCell>
              <TableCell>Verfügbar</TableCell>
              <TableCell>Geblockt</TableCell>
              <TableCell>Angeboten</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={updateOrder}>
            <Droppable droppableId="table">
              {(droppableProvided) => (
                <TableBody
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  sx={{ width: '100%' }}
                >
                  {orderedItems.map((inventory, index) => (
                    <Draggable
                      key={inventory.id}
                      draggableId={inventory.id}
                      index={index}
                      isDragDisabled={isLoading}
                    >
                      {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                        <TableRow
                          hover
                          sx={{ td: { whiteSpace: 'nowrap' } }}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            ...(snapshot.isDragging
                              ? { display: 'inline-table', background: 'rgba(245,245,245, 0.75)' }
                              : undefined),
                          }}
                        >
                          <TableCell sx={{ p: 0 }} {...draggableProvided.dragHandleProps}>
                            <Iconify
                              icon="mi:drag"
                              sx={{ width: 24, height: 24, mx: '8px', opacity: isLoading ? 0.5 : 1 }}
                            />
                          </TableCell>

                          <TableCell sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <Link
                              href={PATH_APP.inventory.view(inventory.id)}
                              noWrap
                              variant="subtitle2"
                              sx={{ color: 'text', cursor: 'pointer' }}
                            >
                              {inventory.name}
                            </Link>
                            <NotePopover note={inventory.note} />
                          </TableCell>

                          <TableCell>{translate(inventory.unit)}</TableCell>
                          <TableCell>{fNumber(inventory.stats?.total)}</TableCell>
                          <TableCell>{fCurrency(inventory.listPrices[0].price)}</TableCell>
                          <TableCell>{fNumber(inventory.stats?.sold)}</TableCell>
                          <TableCell>{fNumber(inventory.stats?.available)}</TableCell>
                          <TableCell>{fNumber(inventory.stats?.blocked)}</TableCell>
                          <TableCell>{fNumber(inventory.stats?.offered)}</TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                  <TableNoData isNotFound={!orderedItems.length} />
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </Scrollbar>
    </Card>
  );
}
