export const de = {
  user: 'Benutzer',
  contact: 'Kontakt',
  PRICE_PER_METER: 'Meter',
  PRICE_PER_MINUTE: 'Min.',
  PRICE_PER_SEAT: 'Sitz',
  PRICE_PER_UNIT: 'Stück',
  PRICE_PER_VOLUME: 'Volumen',
  CANCELED_BY_CLUB: 'Durch Club beendet',
  CANCELED_BY_CUSTOMER: 'Durch Kunden beendet',
  CONTRACT: 'Vertrag',
  DRAFT: 'Entwurf',
  EXPIRED: 'Ausgelaufen',
  OFFER: 'Angebot',
  EXISTING: 'Bestand',
  LEAD: 'Lead',
  FUTURE: 'Zukünftig',
  CURRENT: 'Aktuell',
  OLD: 'Alt',
  NEW: 'Neu',
  CANCELED: 'Gekündigt',
  ONCE: 'Einmalig',
  YEARLY: 'Jährlich',
  renewYears_one: '{{count}} Jahr',
  renewYears_other: '{{count}} Jahre',
  ACCOUNT_ADMIN: 'Club-Administrator',
  ACCOUNT_ADMIN_RESTRICTED: 'Team Lead',
  USER: 'User',
  WORKFLOW: 'Umsetzer',
  RESTRICTED: 'Eingeschränkter Nutzer',
  ACCOUNTING: 'Buchhaltung',
  READONLY: 'Leser',
  OFFER_OPEN: 'Offen',
  OFFER_CANCELED: 'Abgesagt',
  OFFER_DRAFT: 'Entwurf',
  OPEN: 'offen',
  IN_PROGRESS: 'in Bearbeitung',
  DONE: 'erledigt',
  MATCH: 'Spieltagsbuchung',
  MATCHES: 'pro Spieltag',
  SPECIFIC_MATCHES: 'Einzelspiele',
  SEASON: 'pro Saison',
  SINGLE: 'einzeln',
  AVAILABLE: 'Verfügbar',
  SOLD: 'Verkauft',
  ORGANIZATION: 'Unternehmen',
  PERSON: 'Privatperson',
  INVOICE_RECEIVER: 'Rechnungsempfänger',
  MAIN: 'Hauptansprechpartner',
  SIGNATORY: 'Unterzeichner',
  TICKETING: 'Ticketverantwortlicher',
  INVOICE: 'Rechnung',
  CANCELLATION: 'Stornorechnung',
  BARTER: 'Barter',
  BONUS: 'Prämie',
  PERCENTAGE: 'Cash',
  accountingEmail: 'Buchhaltung E-Mail',
  accountingName: 'Buchhaltung Name',
  accountingPhone: 'Buchhaltung Telefon',
  bankAccountOwner: 'Kontoinhaber',
  bankBIC: 'BIC',
  bankIBAN: 'IBAN',
  city: 'Stadt',
  companyName: 'Firmenname',
  country: 'Land',
  courtNumber: 'Registernummer',
  electronicAddress: 'Elektronische Adresse',
  street: 'Straße und Hausnummer',
  taxId: 'Steuernummer',
  valueAddTaxId: 'USt-IdNr.',
  zipCode: 'PLZ',
};
